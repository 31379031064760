import nm from './nm-update.png'
import yoe from './yoe.png'
import aws from './awsfs.png'
import mealprep from './Design-button.png'
import threesisters from './3-sisters2.jpg'
import waffle from './waffle.png'
import Modal from './Modal'
import blink from './Vertical5.png'
import meal from './Meal-Prep-Promo.png'
import b from './btile.png'
import falco from './Falco.png'
import kcrp1 from './kcrp1.png'
import st from './StayInformed1.png'
import ftp from './ForestPlate2.png'
import sidhu from './sidhu.png'
import sidhulogo from './sidhulogo.png'




const projects = [
    {
      id: 1,
      title: 'Natoncks Metsu: Feeding My Cousins',
      src: 'https://www.natoncksmetsu.com/',
      description:
        ' This project merges front-end web development with logo creation, branding, and graphic design - while incoporating indeginous art and minimalist design.',
        url:
        nm,
      services: 'Web Dev & Graphic Design',
      datetime: '2020-03-16',
      category: { title: 'React', href: '#' }
      
    },

    {
      id: 2,
      title: 'Sidhu Farms',
      src: 'https://sidhufarms.com',
      description:
        'React.js & Tailwind website for Sidhu Farms, a farm located in Puyallup Washignton',
        url:
        sidhu,
      services: 'Web Dev & Graphic Design',
      datetime: '2020-03-16',
      category: { title: 'React', href: '#' }
      
    },
    {
      id: 3,
      title: 'King County Reparations Project',
      src: 'https://kingcountyrp.org',
      description:
        'React.js & Tailwind website for KCRP, a documentary being produced about redlining in Seattle, WA',
        url:
        kcrp1,
      services: 'Web Dev & Graphic Design',
      datetime: '2020-03-16',
      category: { title: 'React', href: '#' }
      
    },
    {
        id: 4,
        title: 'AWS X React FS App',
          src: 'https://main.d3n8x1ehrwaz22.amplifyapp.com/',
          description:
            'A Note Taking App with signup and login using React, AWS Amplify, and GraphQl',
            url:
            aws,
          services: 'Authentication, Database',
          datetime: '2020-03-16',
          category: { title: 'AWS', href: '#' }
        
      },
        {
          id: 5,
          title: 'Youth Opportunity Education',
          src: 'https://youth-opportunity-educationv3.vercel.app/',
          description:
            'This website was for Youth Opportunity Education in Yakima. I used React and Chakra UI to organize and show content.',
            url:
            yoe,
          services: 'Web Dev & Graphic Design',
          datetime: '2020-03-16',
          category: { title: 'React', href: '#' }
          
        }
  ]

  /*------------- Graphic Design ------------------*/

  const desprojects = [

    
    {
      id: 1,
      title: 'Forest to Plate #1',
      src: 'https://drive.google.com/file/d/1gYkKJsB1MR2YayrKts6WkteCHMWv9SI_/view?usp=sharing',
      description:
        ' This project merges front-end web development with logo creation, branding, and graphic design - while incoporating indeginous art and refined minimalism.  ',
        url:
        ftp,
      
    },
    {
      id: 2,
      title: 'Sidhu Farms Logo',
      src: 'https://drive.google.com/file/d/19CfpkDRxSTR7j7hUPl-QEdWY0cUNnRJl/view?usp=drive_link',
      description:
        ' This project merges front-end web development with logo creation, branding, and graphic design - while incoporating indeginous art and refined minimalism.  ',
        url:
        sidhulogo,
      
    },
    
    {
      id: 3,
      title: 'Natoncks X Seattle Pride',
      src: 'https://drive.google.com/file/d/19icHhS_9l0P2gchxC3-BVDc7Z5vJC0OE/view?usp=sharing',
      description:
        ' This project merges front-end web development with logo creation, branding, and graphic design - while incoporating indeginous art and refined minimalism.  ',
        url:
        b,
      
    },

    {
      id: 4,
      title: 'Stay Informed IG Story',
      src: 'https://drive.google.com/file/d/19UP3B3pM4eIwLUBWlXs-WhRUc_-uuzEc/view?usp=sharing',
      description:
        ' This project merges front-end web development with logo creation, branding, and graphic design - while incoporating indeginous art and refined minimalism.  ',
        url:
        st,
      
    },
    {
      id: 5,
      title: 'Falco.',
      src: 'https://drive.google.com/file/d/17nKJVkVuGprdHcdxiVV-A_Qqi0w_TMdO/view?usp=drive_link',
      description:
        ' This project merges front-end web development with logo creation, branding, and graphic design - while incoporating indeginous art and refined minimalism.  ',
        url:
        falco,
      
    },
    {
      id: 6,
      title: 'Meal Prep Promo Card',
      src: 'https://drive.google.com/file/d/1oFgyo5nYLYz7nOa3cR7yf5eOyThyUz5q/view?usp=sharing',
      description:
        ' This project merges front-end web development with logo creation, branding, and graphic design - while incoporating indeginous art and refined minimalism.  ',
        url: meal
    },
    {
        id: 7,
        title: '3 Sisters Recipe Card',
          src: 'https://drive.google.com/file/d/1fSjSc3qXkavCNTaPp2HJJQ0NngbDf6HB/view?usp=sharing',
          description:
            ' This project merges front-end web development with logo creation, branding, and graphic design - while incoporating indeginous art and refined minimalism.  ',
            url:
            threesisters,
        
      },

        {
            id: 8,
            title: 'Waffles for Sale',
            src: 'https://drive.google.com/file/d/13PVq_mMEgvJ6LKyURz12aXAmR5-RjuLq/view?usp=sharing',
            description:
              ' This project merges front-end web development with logo creation, branding, and graphic design - while incoporating indeginous art and refined minimalism.  ',
              url:
              waffle,
            
          },

          
    // More projects
  ]
  
  export default function Projects() {
    return (
      <div className="text-white mt-6">
        <div className="mx-auto max-w-7xl px-6 lg:px-3">
          <div className="mx-auto max-w-4xl text-center px-0">
            <h2 className="text-3xl mb-2 px-0 font-bold tracking-tight text-white sm:text-4xl text-center lg:text-left">Projects</h2>
       
          </div>

          <h3 class="mt-20 text-xl mb-4">Dev</h3>

          <div class="relative flex py-2 items-center">
                  <div class="flex-grow border-t border-gray-400"></div>
                    <span class="flex-shrink mx-4 text-indigo-400">

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                    </svg>
                    </span>
                    <div class="flex-grow border-t border-gray-400"></div>
                  </div>

          <div className="mx-auto mt-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">

            
            {projects.map((project) => (
              <article key={project.id} className="flex flex-col items-start ">
                
                <a href={project.src} target="_blank">

                <div className="relative w-full">
                  <img
                    src={project.url}
                    alt=""
                    className="aspect-[16/9] w-full rounded-lg bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                    target="_blank" 
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                </a>
                <div className="max-w-xl">
                 
                  <div className="group relative">
                    <h3 className="mt-6 text-lg font-semibold  leading-6 text-yellow-500 group-hover:text-emerald-500">
                      <a href={project.src}>
                        <span className="absolute inset-0 " target="_blank" />
                        {project.title}
                      </a>
                    </h3>
                  </div>
                
                </div>
              </article>
            ))}
          </div>

          <h3 class="mt-20 text-xl mb-4">Graphic Design</h3>

          <div class="relative flex py-2 items-center">
                  <div class="flex-grow border-t border-gray-400"></div>
                    <span class="flex-shrink mx-4 text-indigo-400">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                    </svg>


                    </span>
                    <div class="flex-grow border-t border-gray-400"></div>
                  </div>


          <div className="mx-auto mt-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">

            
                {desprojects.map((desproject) => (
                <article key={desproject.id} className="flex flex-col items-start">
                                        <a href={desproject.src} target="_blank" >
                            <div className="relative w-full">
                            <img
                                src={desproject.url}
                                alt=""
                                className="aspect-[16/9] w-full rounded-lg bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" target="_blank"
                            />
                        <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    </a>
                    <div className="max-w-xl">
                    
                    <div className="group relative">
                        <h3 className="mt-6 text-lg font-semibold leading-6 text-yellow-500 group-hover:text-emerald-500">
                            <a href={desproject.src} target="_blank" >
                                <span className="absolute inset-0" />
                                {desproject.title}
                            </a> 
                        </h3>
                    </div>
                    
                    </div>
                </article>
                ))}
                </div>






          <h3 class="text-white mt-20 text-xl">Other Projects</h3>

          <ul class="text-white mt-6">

          <li class="mb-10"><a href="https://github.com/moosejaws/reactnative-login" target="_blank" rel="nonreferrer">React Native Google Sign-In<br></br><span class="text-emerald-500">React Native, Firebase</span></a>
            </li>


            <li class="mb-10"><a href="https://github.com/moosejaws/Yearly-App" target="_blank" rel="nonreferrer">Yearly App<br></br><span class="text-emerald-500">MongoDB, Express, React, NodeJS, Bootstrap</span></a>
            </li>

            <li><a href="https://github.com/moosejaws/Voting-App" target="_blank" rel="nonreferrer">Voting App<br></br><span class="text-emerald-500">MongoDB, Express, React, NodeJS, Bootstrap</span></a>
            </li>

            
          </ul>

        </div>
        
      </div>
    )
  }
  