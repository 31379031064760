import snow from './snow.jpeg'
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/20/solid'
import Projects from './components/Projects'
import css from '../src/index.css'
import Tech from './components/Tech'
import Credentials from './components/Credentials'
import Modal from './components/Modal'

export default function App() {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
      
      <img
        src={snow}
        alt=""
        className="absolute inset-0 -z-10 h-full w-full blur object-cover "
      />

      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu "
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-5xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          
        </div>


        <div className="text-center gap-x-6 bg-emerald-500 px-2 py-3 sm:px-3.5 rounded-sm ">
      <p id="scrollme" className=" text-center text-xs lg:text-md text-black ">
        <a href="https://oicofwa.org/give/" target="_blank" rel="nonreferrer">
          <strong className="">Donate to OIC of Yakima</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          Support growth in Yakima, WA <span aria-hidden="true">&rarr;</span>
        </a>
      </p>
      
    </div>

        <div className="bg-zinc-950 px-1 py-28 lg:px-8 mt-12 rounded-3xl text-white 	">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700 px-6 -mt-10">


            <div class=" items-center text-center">
                <svg id="blinking" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.2}  className="w-32 h-32 pt-0 stroke-emerald-500 center m-auto mb-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z" />
                      
                </svg>

                <h2 id="silk" className="text-5xl font-Silkscreen tracking-tight text-yellow-500 sm:text-5xl md:text-7xl pr-2">Reina True</h2>
                
                <div class="w" scrollamount="100">
                <div class="mt-8 mb-8 lg:text-2xl font-semibold text-indigo-500 text-2xl">
          <p>Web Development & Graphic Design</p>
        </div>



          <div class="text-yellow-500 inline lg:text-xl mb-2 text-xl " >

            
          <p class=" lg:pr-6 font-bold text-emerald-500 mb-4">reina@snowyowl.cloud  </p>
          <div class="text-yellow-500 inline-flex lg:text-xl mb-10 text-xl " >

            <a href="https://www.linkedin.com/in/reinarodriguez/" target="_blank" rel="noreferrer" class="hover:text-pink-500"><p>LinkedIn</p></a>
            <p></p>
            <a href="https://github.com/moosejaws" target="_blank" rel="noreferrer"><p class="px-4 hover:text-pink-500">Github</p></a>

          </div>
          </div>


        </div>
            </div>


       
       

        <p className="mt-8 text-xl text-white text-center leading-8">
        Crafting Digital Stories with Minimalist Flair and Bold Hues</p>
        <div className="mt-4 max-w-2xl text-white">
          <p>

          </p>
          <ul role="list" className="mt-16 max-w-2xl space-y-8 text-gray-600">
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-emerald-500" aria-hidden="true" />
              <span class="text-white"> 
                <div>
                <strong className="font-semibold text-white">Web Development</strong> </div>
                <br></br>

                <span class="text-yellow-500">
                  Front-End:</span> Bring your brand to life on the web with style and a cohesive user experience. I use visually appealing and consistent front-end design to help establish a strong and memorable brand presence online.
                  <br></br>
                  <div class="relative flex py-5 items-center">
                  <div class="flex-grow border-t border-gray-400"></div>
                    <span class="flex-shrink mx-4 text-emerald-500 animate-bounce"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                      </svg>
                    </span>
                    <div class="flex-grow border-t border-gray-400"></div>
                  </div>
              
              </span>
            </li>
            <li className="flex gap-x-3 ">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-emerald-500" aria-hidden="true" />
              <span class="text-white">
                <strong className="font-semibold text-white mb-2">Graphic Design</strong> 
                <br></br>
                <br></br>

                Transform your ideas into tangible and digital works of art with comprehensive print and digital design services. From eye-catching brochures and business cards to engaging social media graphics and web elements. My goal is to ensure your brand stands out in both physical and virtual spaces.
              </span>
            </li>
            <Tech />

          </ul>

          </div>
          <Projects />

         </div>

    </div>

    
    

      </div>
    </div>
  )
}
