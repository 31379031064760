export default function Tech() {
    return (
      <div className="bg-none py-10 sm:py-12 mb-20">
        <div className="mx-auto px-2 lg:px-1">
          <h2 className="lg:text-left  text-center text-3xl font-bold leading-8 sm:text-center text-white">
            Tech I use
          </h2>
          <div className="-mx-6 grid grid-cols-2 gap-12 overflow-hidden sm:mx-0 sm:rounded-2xl items-center  md:grid-cols-4 mt-10 ">

            <i class="devicon-javascript-plain colored text-7xl p-2"></i>
            <i class="devicon-figma-plain colored text-7xl p-2"></i>
            <i class="devicon-react-original-wordmark colored text-7xl p-2"></i>
            <i class="devicon-nodejs-plain-wordmark colored text-7xl p-2"></i>
            <i class=" colored text-7xl p-2"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#38bdf8" d="M64.004 25.602c-17.067 0-27.73 8.53-32 25.597c6.398-8.531 13.867-11.73 22.398-9.597c4.871 1.214 8.352 4.746 12.207 8.66C72.883 56.629 80.145 64 96.004 64c17.066 0 27.73-8.531 32-25.602q-9.6 12.803-22.399 9.602c-4.87-1.215-8.347-4.746-12.207-8.66c-6.27-6.367-13.53-13.738-29.394-13.738M32.004 64c-17.066 0-27.73 8.531-32 25.602Q9.603 76.799 22.402 80c4.871 1.215 8.352 4.746 12.207 8.66c6.274 6.367 13.536 13.738 29.395 13.738c17.066 0 27.73-8.53 32-25.597q-9.6 12.797-22.399 9.597c-4.87-1.214-8.347-4.746-12.207-8.66C55.128 71.371 47.868 64 32.004 64m0 0"/></svg>  </i>          

            <i class="devicon-express-original-wordmark text-white text-7xl p-2"></i>
            
            <i class="devicon-amazonwebservices-plain-wordmark colored text-7xl p-2"></i>
            
            <i class="devicon-firebase-plain-wordmark colored text-7xl p-2"></i>

          </div>
        </div>
      </div>
    )
  }